import { Box, Button } from '@qwealth/qcore';
import { saveKycStatus, selectUser } from '@qwealth/qdata';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import KYCFormComponents from './KYCFormComponents';
const KYCReview: React.FC = () => {
  const navigate = useNavigate();
  const QID = useSelector(selectUser)?.QID ?? '';
  const params = useParams();

  return (
    <Box boxShadow="default" display="flex" flexDirection="column" p="large">
      <Box
        boxShadow="layout"
        p="large"
        padding="largest"
        backgroundColor="primary"
        color="white"
        fontWeight="semiBold"
        fontSize="large"
        borderRadius="default"
        marginTop="large"
      >
        During our annual review process, we ask you to review the information on file and provide
        us with any changes that may have occurred. Each section will allow you to update the
        specific section. If there are no changes you can proceed to the next step which will be a
        series of short questions to complete by clicking the button at the bottom of the form.
      </Box>
      <KYCFormComponents />
      <Button
        size="large"
        type="submit"
        onClick={async () => {
          navigate({ pathname: `/annual-review/${params['id']}/rtq` });
        }}
      >
        Confirm information is correct as stated
      </Button>
    </Box>
  );
};

export default KYCReview;
